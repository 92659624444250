<template>
<div class="page">
  <div class="page-content-container">
    <div class="section-image-container">
      <img class="page-image" :src="`${BACKEND_URL}/storage/map/map.jpg`">
      <div style="left: 2%; top: 2%;" class="point-container">
        <a @click="$router.go(-1)">
          <font-awesome-icon :icon="['fas', 'angle-left']" class="point-icon" />
        </a>
      </div>
      <div class="map-point" :style="`animation-delay: -3s; left:${mapX}%; top:${mapY}%;`"></div>
      <div class="map-point" :style="`animation-delay: 0s; left:${mapX}%; top:${mapY}%;`"></div>
    </div>
  </div>
</div>
</template>

<script>
import metaDataMixin from '@/metadata-mixin'

export default {
  name: 'TheMap',
  mixins: [metaDataMixin],
  created(){
    this.BACKEND_URL = process.env.VUE_APP_BACKEND_URL
    this.SITE_NAME = process.env.VUE_APP_SITE_NAME
    this.setMetadata(this.SITE_NAME, 'Map')
  },
  computed:{
    mapX(){
      return this.$store.getters.mapXValue
    },
    mapY(){
      return this.$store.getters.mapYValue
    },
    locationMetadata(){
      return this.$store.getters.locationMetadataValue
    }
  },
  watch:{
		$route(){
			if(this.$route.name==='TheMap'){
        if(this.$route.path==='/map'){
          this.setMetadata(this.SITE_NAME, 'Map')
        }else{
          this.setMetadata(this.SITE_NAME, this.locationMetadata)
        }

			}
		}
  }
}
</script>

<style scoped>
.map-point {
    animation: scaleIn 2.25s infinite cubic-bezier(.36, .11, .89, .32);
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    height: 32px;
    opacity: 0;
    position: absolute;
    width: 32px;
}

@keyframes scaleIn {
    from {
        transform: scale(.75, .75);
        opacity: .75;
    }
    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}


</style>
