<template>
    <div class="page" v-if="location">
		<div class="page-content-container">
			<div class="section-image-container" :class="sectionImageDisplay">
				<img v-if="location.location_image" :src="`${BACKEND_URL}/storage/locations/${location.location_image}`" :alt="location.name" class="page-image">
				<div style="right: 2%; top: 2%;" class="point-container">
					<router-link :to="{ name: 'TheMap' }" class="map-link">
						<font-awesome-icon :icon="['fas', 'map']" class="point-icon" />
					</router-link>
				</div>
				<div v-for="point in location.points" :key="point.id" :style="`left: ${point.x}%; top: ${point.y}%; transform: rotate(${point.rotate}deg)`" class="point-container">
					<div v-if="point.link">
						<router-link v-if="point.type==='location'" :to="{ name: 'LocationDetails', params: { slug: point.link } }">
							<font-awesome-icon :icon="['fas', 'arrow-up']" class="point-icon" />
						</router-link>
					</div>
					<div v-else-if="point.type==='text'">
						<font-awesome-icon :icon="['fas', 'file-alt']" @click="showSectionContent(point.content, false, false, false)" class="point-icon" />
					</div>
					<div v-else-if="point.type==='detail'">
						<font-awesome-icon :icon="['fas', 'eye']" @click="showSectionContent(point.content, false, point.point_image, point.alt)" class="point-icon" />
					</div>
					<div v-else-if="point.type==='audio'">
						<font-awesome-icon :icon="['fas', 'file-audio']" @click="showSectionContent(point.content, point.point_audio, false, false)" class="point-icon" />
					</div>
				</div>
			</div>
			<div class="section-content-container" :class="sectionContentDisplay">
				<div class="section-content-back point-container" @click="hideSectionContent">
					<font-awesome-icon icon="angle-left" class="point-icon" />
				</div>
				<div class="section-content">
					<div v-if="sectionDetailImage" class="section-content-detail-image">
						<img :src="`${BACKEND_URL}/storage/points/images/${sectionDetailImage}`" :alt="sectionDetailAltText" class="page-image">
					</div>
					<div v-html="sectionContent" class="section-content-text"></div>
					<div v-if="sectionAudioFile" class="section-content-audio">
						<audio controls="controls" :src="`${BACKEND_URL}/storage/points/audio/${sectionAudioFile}`" type="audio/mpeg"></audio>
					</div>
				</div>
			</div>
			<ModalComponent v-if="showOverlay" @close="removeLocation">
				<template #overlayContentHeader>
					{{ location.name }}
				</template>
				<template #overlayContent>
					<div v-html="location.intro_text"></div>
				</template>
			</ModalComponent>
		</div>
    </div>
	<div v-else>
		<div class="warning-container">
			<div class="warning-content">
			</div>
		</div>
	</div>
</template>

<script>
//import store from '@/store.js'
import axios from 'axios'
import ModalComponent from '@/components/ModalComponent'
import metaDataMixin from '@/metadata-mixin'

export default{
	mixins: [metaDataMixin],
    data () {
        return {
			locations: [],
			locationsUnvisited: [],
			sectionAudioFile: '',
			sectionContentDisplay: '',
			sectionContent: '',
			sectionDetailImage: '',
			sectionDetailALtText: '',
			sectionImageDisplay: '',
		}
    },
	components:{
		ModalComponent
	},
	created(){
		this.SITE_NAME = process.env.VUE_APP_SITE_NAME
		this.BACKEND_URL = process.env.VUE_APP_BACKEND_URL
		axios.get(`${this.BACKEND_URL}/api/locations`)
			.then(response => {
				this.locations = response.data
				if(this.locationsUnvisited.length===0){
					this.populateLocationsUnvisited()
				}
				this.setLocationMetadata(this.location.name)
				this.setMetadata(this.SITE_NAME, this.location.name)
				this.setMapCoords(this.location.x, this.location.y)
			})
			.catch((err) => console.log(err))
	},
	props: {
		slug: {
			type: String,
			required: true
		}
	},
	computed: {
	    location () {
			return this.locations.find(
				location => location.slug == this.slug
			)
		},
		showOverlay(){
			let locationsUnvisitedResult=this.locationsUnvisited.find(locationUnvisited => locationUnvisited===this.$route.params.slug)
			if( locationsUnvisitedResult!==undefined){
				return true;
			}else{
				return false;
			}
		}
	},
	methods: {
	    findToLocation (toLocation) {
			return this.locations.find(
				location => location.slug == toLocation
			)
		},
		removeLocation(){
			for(let i = this.locationsUnvisited.length - 1; i >= 0; i--){
				if(this.locationsUnvisited[i]===this.$route.params.slug){
					this.locationsUnvisited.splice(i, 1)
				}
			}
		},
		populateLocationsUnvisited() {
			//This is the list of locations we have not yet visited.
			//So it is the list of locations where we do show the overlay on first location visit.
			//When the component first loads, add all the locations from the Ajax call to this array.
			this.locations.forEach((location)=>this.locationsUnvisited.push(location.slug))
		},
		hideSectionContent(){
			this.sectionImageDisplay = 'section-display'
			this.sectionContentDisplay = 'section-no-display'
			setTimeout(() => {
				this.sectionAudioFile = ''
				this.sectionDetailImage = ''
			}, 2000)
		},
		setLocationMetadata(metadata){
			this.$store.dispatch('updateLocationMetadata', metadata)
		},
		setMapCoords(x,y){
			this.$store.dispatch('updateMapCoords', {x: x, y: y})
		},
		showSectionContent(content, pointAudio=false, pointImage=false, pointAltText=false){
			this.sectionContent = content
			if(pointAudio){
				this.sectionAudioFile = pointAudio
			}else if(pointImage){
				this.sectionDetailImage = pointImage
				this.sectionDetailAltText = pointAltText
			}
			this.sectionImageDisplay = 'section-no-display'
			this.sectionContentDisplay = 'section-display'
		},
	},
	watch: {
		$route(){
			//If the current page is served by this component, then this watch will do something.
			//If the watch picks up a change but the current view is served by another component, do nothing.
			//We do this because the watch on TheMap is also in operation on our routes and we don't have them both triggering.
			if(this.$route.name==='LocationDetails'){
				//We want to a) set our metadata and b) record the x and y coordinates.
				//However, the route has changed and we are about to load the corresponding page for that route.
				//Therefore, we need to fetch the location details not for the current location but the next one.
				//Because our route has changed, the slug actually corresponds right now to the next location.
				//Therefore we can use the slug to fetch the location details.
				if(this.$route.params.slug==='map'){
					this.setMetadata(this.SITE_NAME, 'Map')
				}else{
					let toLocationDetails=this.findToLocation(this.$route.params.slug)	
					this.setLocationMetadata(toLocationDetails.name)
					this.setMapCoords(toLocationDetails.x, toLocationDetails.y)
					this.setMetadata(this.SITE_NAME, toLocationDetails.name)
				}
			}
		}
	}
}
</script>

<style scoped>
.section-content-text{
	color:#fff;
}
.section-content-container{
  color: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  opacity: 0;
  overflow-y:auto;
  overflow-x:hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.section-content-back{
    left: 2%;
    top: 2%;
}
.section-content{
  display: block;
  margin: 0 2%;
  position:relative;
  text-align: left;
  top: 12%;
  width:100%;
}
.section-content-text{
	margin-right: 2rem;
	padding:1rem 1rem 1rem 1rem;
}
.section-content-detail{
	padding:1rem 1rem 1rem 1rem;
	width:100%;
}
.section-content-audio{
	padding:1rem 1rem 1rem 1rem;
	width:100%;
}
.section-display{
	animation: fadeIn ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.section-no-display{
	animation: fadeOut ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.section-image-container{
  position: relative;
  top: 0;
  left: 0;
  z-index:2;
}
.warning-container{
	align-items:center;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	position:absolute;
	top: 0;
	width:100vw;
}
.warning-content{
	color:#ff0000;
	text-align: center;
}

@media only screen and (min-width: 768px) {
	.section-content-text{
		font-size:3rem;
	}
}
</style>