import { createStore } from 'vuex'

export default createStore({
  state: {
    mapX: 0,
    mapY: 0,
    locationMetadata: ''
  },
  getters: {
    mapXValue: state => state.mapX,
    mapYValue: state => state.mapY,
    locationMetadataValue: state => state.locationMetadata,
  },
  mutations: {
    UPDATE_MAP_COORDS(state, mapNewCoords){
      state.mapX = mapNewCoords.x
      state.mapY = mapNewCoords.y
    },
    UPDATE_LOCATION_METADATA(state, metadata){
      state.locationMetadata = metadata
    }
  },
  actions: {
    updateMapCoords(context, mapNewCoords){
      context.commit('UPDATE_MAP_COORDS',mapNewCoords)
    },
    updateLocationMetadata(context, metadata){
      context.commit('UPDATE_LOCATION_METADATA', metadata)
    }
  },
  modules: {
  }
})
