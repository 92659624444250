import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from '@/store'

//Import relevant Font Awesome packages.
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//Import and make available each icon that you wish to use.
import { faAngleLeft, faArrowUp, faEye, faFileAlt, faFileAudio, faMap, faTimes } from '@fortawesome/free-solid-svg-icons'
library.add(faAngleLeft, faArrowUp, faEye, faFileAlt, faFileAudio, faMap, faTimes)

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
