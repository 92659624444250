import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import LocationDetails from '../views/LocationDetails.vue'
import TheMap from '../views/TheMap.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/location/:slug',
		name: 'LocationDetails',
		props: true,
		component: LocationDetails,
	},
	{
		path: '/map',
		name: 'TheMap',
		props: true,
		component: TheMap,
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: NotFound
	}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
