<template>
  <main>
    <router-view v-slot="{ Component }">
      <transition name="overlay" mode="out-in">
		    <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </main>
</template>

<script>
  import 'animate.css'
  export default{
    name: 'App'
  }
</script>


<style>
body, html{
  background-color: #000;
  color:#fff;
  font-size: 62.5%;
  height: 100%;
  margin: 0;
}
a{
  color:#fff;
}
body{
  font-size: 1.6rem;
}
.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 1s ease;
}
.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}
.overlay-enter-to,
.overlay-leave-from {
  opacity: 1;
}
.page{
  margin: 0 auto;
  max-width: 1000px;
  position:relative;
  text-align: center;
}
.page-content-container{
    display:inline-block;
    height: auto;
    margin:0 auto;
    position: relative;
    width: auto;
}
.page-image{
    height:100%;
    max-height:98.5vh;
    max-width:1000px;
    width:100%
}
.point-container{
    background-color:#fde923;
    border-radius:50%;
    cursor: pointer;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 32px;
}
.point-icon{
  color: #000;
  height: 32px;
  width: 32px;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
    z-index:2;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
    opacity: 0;
    z-index:1;
	}
}
</style>
