export default{
    methods: {
        setMetadata(siteName, metaDataContent){
            metaDataContent = `${metaDataContent} - ${siteName}`
            let titleElement = document.querySelector('head title')
            let metaDescriptionElement = document.querySelector('head meta[name="description"]')
            titleElement.textContent = metaDataContent
            metaDescriptionElement.setAttribute('content', metaDataContent)
        }
    }
}