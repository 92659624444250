<template>
<div class="overlay-container">
    <div class="overlay-close point-container" @click="$emit('close')">
      <font-awesome-icon icon="times" class="point-icon" />
    </div>
    <div class="overlay-content-container">
      <div class="overlay-content-header">
        <slot name="overlayContentHeader"></slot>
      </div>
      <div class="overlay-content">
        <slot name="overlayContent"></slot>
      </div>
    </div>
</div>
</template>

<script>
  export default{
    name: 'ModalComponent',
  }
</script>

<style scoped>
.overlay-container{
    align-items:center;
    background-color: rgba(0,0,0,0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
.overlay-close{
    right: 2%;
    top: 2%;
}
.overlay-close-icon{
  color: #000;
  height: 32px;
  width: 32px;
}
.overlay-content-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top:40px;
}
.overlay-content-header{
	animation: fadeIn ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
  display: block;
  font-size:3rem;
  opacity: 0;
  padding:0.5rem;
  word-break:break-all;
}
.overlay-content{
	animation: fadeIn ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
  display: block;
  font-size:2rem;
  margin: 0 2%;
  opacity: 0;
  padding:0.5rem;
}
.sectionHide{
	animation: fadeOut ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
.sectionShow{
	animation: fadeIn ease 5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media only screen and (min-width: 768px) {
	.overlay-content{
		font-size:3rem;
	}
  .overlay-content-header{
    font-size:6rem;
  }
}
</style>